import constants from 'app/constants'
import { parseCurrencyParameter } from 'common/util/currencies'

function logSumExp(...elements) {
  const max = Math.max(...elements)
  return max + Math.log(elements.map(e => Math.exp(e - max)).reduce((acc, value) => acc + value, 0))
}

function lmsrPrice(question, outcome, shares, currencyId) {
  // TODO: please refactor this on the future:
  const liquidity = question.scoring_rule_metadata.lmsr[currencyId].liquidity_param
  const outcomeList = []
  const shareList = []
  const newShareList = []
  const activeOutcomes = question.outcomes.filter(o => !o.disabled)

  if (activeOutcomes.length > 0 && typeof activeOutcomes[0] === 'object') {
    outcomeList.push(...activeOutcomes)
  } else {
    throw 'Error while calculating lmsr price: Not valid outcomes for question'
  }

  for (const questionOutcome of outcomeList) {
    shareList.push(questionOutcome.shares[currencyId] / liquidity)
    if (questionOutcome.id === outcome.id) {
      newShareList.push((questionOutcome.shares[currencyId] + shares) / liquidity)
    } else {
      newShareList.push(questionOutcome.shares[currencyId] / liquidity)
    }
  }

  const newCost = liquidity * logSumExp(...newShareList)
  const oldCost = liquidity * logSumExp(...shareList)
  return newCost - oldCost
}

function lmsrShares(question, outcome, balance, selectedCurrency) {
  // The formula for two outcomes, when the transaction
  // is on outcome 1 is defined as:
  //
  // shares(c) = b * ln(e^(c/b) * (e^(q1/b) + e^(q2/b)) - e^(q2/b)) - q1
  //
  // where c is the money amount we want to pay
  // q1 is #shares on outcome 1
  // q2 is #shares on outcome 2
  // b is the liquidity param
  //
  // for more explanation, see this link on wolfram alpha
  // https://www.wolframalpha.com/input/?i=solve++c+%3D+b+*+ln(+e%5E((q1%2Bs)%2Fb)+%2B+e%5E(q2%2Fb)+)+-+b+*+ln(+e%5E(q1%2Fb)+%2B+e%5E(q2%2Fb)+)+for+s

  // FIXME: Handle Exponential Overflow on every Math.exp on this function.
  const currencyId = parseCurrencyParameter(selectedCurrency)
  const liquidity = question.scoring_rule_metadata.lmsr[currencyId].liquidity_param
  const outcomeList = []
  const activeOutcomes = question.outcomes.filter(o => !o.active)

  if (activeOutcomes.length > 0 && typeof activeOutcomes[0] === 'object') {
    outcomeList.push(...activeOutcomes)
  } else {
    throw 'Error while calculating lmsr shares: Not valid outcomes for question'
  }

  const sharesSumAll = outcomeList.reduce((acc, o) => {
    return acc + Math.exp(o.shares[currencyId] / liquidity)
  }, 0)

  const sharesSum = outcomeList
    .filter(o => o.id !== outcome.id)
    .reduce((acc, o) => {
      return acc + Math.exp(o.shares[currencyId] / liquidity)
    }, 0)

  const balanceExp = Math.exp(balance / liquidity)
  const shares = liquidity * Math.log(balanceExp * sharesSumAll - sharesSum) - outcome.shares[currencyId]
  return shares
}

function lslmsrPrice(question, outcome, shares, selectedCurrency, position) {
  let shareList = []
  let newShareList = []
  let shareSum = 0
  const activeOutcomes = question.outcomes.filter(o => !o.disabled)

  //TODO: this should be refactored in a proper moment
  const currencyId = parseCurrencyParameter(selectedCurrency)
  const tax = question.scoring_rule_metadata.lslmsr[currencyId].tax
  const alpha = tax / (activeOutcomes.length * Math.log(activeOutcomes.length))

  for (const questionOutcome of activeOutcomes) {
    shareList.push(questionOutcome.shares[currencyId])
    shareSum += questionOutcome.shares[currencyId]
    if (position === constants.position.SHORT) {
      // ? if the transaction is a short, we simply add the shares for all the other oucomes
      if (questionOutcome.id === outcome.id) {
        newShareList.push(questionOutcome.shares[currencyId])
      } else {
        newShareList.push(questionOutcome.shares[currencyId] + shares)
      }
    } else {
      // ? if the transaction is a long, we simply add the shares for the outcome we want to buy

      if (questionOutcome.id === outcome.id) {
        newShareList.push(questionOutcome.shares[currencyId] + shares)
      } else {
        newShareList.push(questionOutcome.shares[currencyId])
      }
    }
  }

  shareList = shareList.map(e => e / (alpha * shareSum))
  newShareList = newShareList.map(e => e / (alpha * (shareSum + shares)))
  const oldCost = alpha * shareSum * logSumExp(...shareList)
  const newCost = alpha * (shareSum + shares) * logSumExp(...newShareList)
  return newCost - oldCost
}

function lslmsrShares(question, outcome, amount, currencyId, minShares, maxShares, position) {
  /**
   * * on this function we are trying to find the shares with the given amount
   * * we do that by using the formula for the lslmsr scoring rule
   * * we try 10000 , each time we move the maxShare and the minShare
   * * closer to the unknown share (x) that we want to find.
   * * we stop when lslmsrPrice(...,minShare,...) == lslmsrPrice(...,maxShare,...)
   * *
   * */

  //
  for (let i = 0; i < 10000; i++) {
    const p0 = lslmsrPrice(question, outcome, minShares, currencyId, position)
    const p1 = lslmsrPrice(question, outcome, maxShares, currencyId, position)
    if (p1 == p0) {
      return maxShares
    }
    const n = maxShares - ((p1 - amount) * (maxShares - minShares)) / (p1 - p0)
    minShares = maxShares
    maxShares = n
  }
  return minShares
}

export { lmsrPrice, lmsrShares, lslmsrPrice, lslmsrShares }
