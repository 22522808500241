export default {
  PLAY_MONEY: 'play_money',
  REAL_MONEY: 'real_money',
  CANONICAL_CURRENCY: 'BTC',
  IDLE: 'idle',
  LOADING: 'loading',
  COMPLETE: 'complete',
  ERROR: 'error',
  SAVING: 'saving',
  REAL_MONEY_PLACEHOLDER: 'REAL_MONEY_PLACEHOLDER',
  position: {
    SHORT: 'short',
    LONG: 'long',
  },
}
